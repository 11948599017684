import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="nyhet col-sm-10">
      <h1>Utvecklas i din yrkesroll genom våra professionella nätverk</h1>

      <span className="ingress">
        Rörlighet i staten erbjuder 17 nätverk inom en rad områden där du som
        anställd har möjlighet att dela erfarenheter, lära dig mer och få
        inspiration från yrkeskollegor på andra myndigheter. Till våren startar
        även ett nytt nätverk inom strategisk kompetensförsörjning och till
        hösten ett inom avtalsuppföljning.
      </span>
      <br />
      <br />

      <p>
        <span>
          I nätverken möts personer som delar liknande arbetsuppgifter för att
          lära av varandra, bolla aktuella utmaningar och få inspiration. För
          dig som arbetar på en myndighet inom Rörlighet i staten är det
          kostnadsfritt att gå med. Nätverken är ett av flera sätt att stärka
          samarbetet över myndighetsgränserna med målet att göra staten till
          Sveriges bästa arbetsplats.{" "}
        </span>
      </p>
      <p>
        <span>
          Nätverken ses fyra till sex gånger per år – ofta digitalt för att alla
          ska ha möjlighet att vara med. Tillsammans enas deltagarna om vilka
          frågor man vill fokusera på.{" "}
        </span>
      </p>
      <p>
        Vad händer i nätverken och på vilka sätt har det varit givande? Möt tre
        medlemmar och läs mer om hur du går med i ett nätverk inom ditt område.
      </p>
      <p>
        <img
          style={{ width: "596px", height: "335px" }}
          src="https://rorlighetistaten.se/media/1459/ceciliaforsberg_nicklasvabi_linneaelovsson.png?width=596&amp;height=335&amp;mode=max"
          alt=""
          data-udi="umb:/https://rorlighetistaten.se/media/6d45af2e4b4c47769a6715fcaaf818d1"
        />
        <br />
        <em>
          Cecilia Forsberg, Nicklas Vabi och Linnea Elovsson är aktiva i nätverk
          genom Rörlighet i staten.
        </em>
      </p>
      <p>
        <strong>
          <br />
          ”Vi har tagit in intressanta föreläsare till våra nätverksmöten”
          <br />
        </strong>
        Cecilia Forsberg, kommunikatör på Spelinspektionen och medlem i
        nätverket Kommunikation:
      </p>
      <p>
        <span>
          – Jag uppskattar nätverket för att kunna få ta del av olika idéer och
          lösningar på hur andra har gjort när man själv har kanske kört fast i
          sin kreativitet eller stöter på problem. Hur har då andra löst det?
          Men det är även en stor inspirationskälla till hur man kan göra nya
          saker och utvecklas i sitt kommunikationsarbete. Vi lär av varandra
          helt enkelt och delar med oss av erfarenheter och goda exempel.
        </span>
      </p>
      <p>
        <span>– </span>Vi har en agenda för varje möte där vi har ett tema eller
        ämne vi tar upp och kan förbereda oss inför. Det kan handla om allt från
        internkommunikation eller arbetet med tillgänglighet. Vi har ibland
        tagit in intressanta föreläsare till våra nätverksmöten där de inbjudna
        pratat om exempelvis kriskommunikation eller hur man jobbar med event
        och seminarier/webbinarier.
        <br />
        <br />
      </p>
      <p>
        <strong>
          ”Genom nätverket både bidrar man och får för<span>s</span>lag på andra
          och kanske bättre sätt att arbeta”
          <br />
        </strong>
        Nicklas Vabi, verksjurist på Rymdstyrelsen och medlem i nätverket
        Juridik:
      </p>
      <p>
        <span>
          – Det är intressant att träffa juristkollegor från andra myndigheter
          och höra om deras lösningar på samma juridiska frågeställningar som
          ens egen myndighet ställs inför. Genom nätverket både bidrar man och
          får förslag på andra och kanske bättre sätt att arbeta med vissa
          frågor som är gemensamma för alla myndigheter i staten.&nbsp;
        </span>
      </p>
      <p>
        <span>– </span>Vi har till exempel diskuterat tillämpningen av vissa
        specifika bestämmelser i offentlighets- och sekretesslagen och gått
        igenom domstolspraxis där bestämmelserna har prövats. Bland annat har vi
        gått igenom hur olika domstolar i flera fall bedömt att uppgifter om
        anställdas namn och befattning på en aggregerad nivå omfattas av
        försvarssekretess även för myndigheter och verksamheter som bara har
        civila uppgifter.
        <br />
        <br />
      </p>
      <p>
        <strong>
          ”Jag var nyfiken på hur andra myndigheter arbetade och hanterade
          utmaningar och svåra frågor”
          <br />
        </strong>
        Linnea Elovsson, metodutvecklare på Spelinspektionen, tog initiativ till
        att starta nätverket Tillsyn när hon började arbeta inom
        tillsynsverksamheten:
      </p>
      <p>
        <span>
          – Jag var nyfiken på hur andra myndigheter arbetade och hanterade
          utmaningar och svåra frågor inom tillsynsområdet. Det jag uppskattat
          mest är att få ta del av de andras värdefulla erfarenheter från
          tillsynsarbetet och de intressanta diskussioner vi haft vid våra
          nätverksträffar. Att få ta del av hur andra arbetar har varit väldigt
          värdefullt för mig i mitt arbete med att utveckla våra metoder och
          arbetssätt för tillsyn. Även om myndigheterna har olika uppdrag så
          finns det väldigt många gemensamma utmaningar och frågor där vi kan
          lära av varandra.&nbsp;
        </span>
      </p>
      <p>
        <span>– </span>Vid våra digitala träffar som vi har cirka varannan månad
        har vi bland annat haft temat riskanalys för tillsyn, beslut för
        avskrivning/avslut av tillsyn, metoder för prioritering av
        tillsynsinsatser, arbetssätt för tillsyn och arbetssätt för uppföljning
        och utvärdering av genomförd tillsyn. Mellan träffarna har vi möjlighet
        att ställa frågor till de andra för att få råd eller höra om hur andra
        hanterat en utmaning eller fråga.&nbsp;&nbsp;
        <br />
        <br />
      </p>
      <h2>
        <span className="normaltextrun">
          Nätverk söker nya medlemmar – vi vill bli fler!
        </span>
      </h2>
      <p>
        Jobbar du med sociala kanaler, administration, publikationsutgivning,
        strategisk kompetensförsörjning eller som registrator? Vi har nätverk
        för dig och vill bli fler att utbyta idéer med. Under året startas även
        två nya nätverk som söker medlemmar, ett för dig som arbetar med
        strategisk kompetensförsörjning och ett inom avtalsuppföljning.
      </p>
      <h3>Vill du starta ett nytt nätverk?</h3>
      <p>
        Det finns önskemål om att starta ett nätverk för processutveckling och
        ett för artificiell intelligens. Om du är intresserad av att starta
        eller delta i dessa är du varmt välkommen att höra av dig till{" "}
        <a href="mailto:annika.hagg@energimyndigheten.se">
          annika.hagg@energimyndigheten.se
        </a>
        . Vi välkomnar initiativ till nya nätverk, hjälper till att hitta
        deltagare och kan även ge tips kring upplägg.
      </p>
      <p>
        Jobbar du inom andra områden och är intresserad av erfarenhetsutbyte kan
        förhoppningsvis något av våra andra nätverk passa.
      </p>

      <p>
        <br />
        Här kan du{" "}
        <a href="/dela-erfarenhet-i-naetverk/paagaaende-naetverk/">
          läsa mer om nätverken och ansöka om att gå med
        </a>
        .
      </p>
    </div>
  );
}
export default Page;
