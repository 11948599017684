import { Link, useNavigate } from "react-router-dom";

import OpenPositionsList from "../../components/OpenPositionsList/OpenPositionsList";
import { useEffect, useState } from "react";
import { GetHandler } from "../../app/api/ApiHandler";

const content = {
  cards: [
    {
      title: "Nyheter och inspiration",
      description:
        "Följ RIS på <a href='https://www.linkedin.com/company/r%C3%B6rlighet-i-staten/'>LinkedIn</a> för att få aktuell information om uppdrag tillgängliga för inlån och övriga aktiviteter.",
      link: "Nyheter",
      image:
        "https://rorlighetistaten.se/media/1382/nader-svaerd-2.jpg?width=435&height=240&mode=max",
    },
    {
      title: "Program för din utveckling",
      description:
        "Kompetensutvecklande program för dig som arbetar på någon av myndigheterna anslutna till Rörlighet i staten.",
      link: "program-foer-din-utveckling",
    },
    {
      title: "Dela erfarenheter i nätverk",
      description:
        "Nätverka med myndighetskolleger. Gå med i befintligt nätverk eller starta nytt.",
      link: "dela-erfarenhet-i-naetverk",
      image:
        "https://rorlighetistaten.se/media/1459/ceciliaforsberg_nicklasvabi_linneaelovsson.png?width=596&height=335&mode=max",
    },
    {
      title: "Jobba på annan myndighet",
      description:
        "Praktisera eller ta dig an ett särskilt uppdrag på annan myndighet.",
      link: "jobba-paa-annan-myndighet",
    },
  ],
};

function Page() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const linkClick = (link: string) => {
    navigate(link);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const content = await GetHandler("openPositions");
      setData(content);
    };
    fetchData();

    setLoading(false);
  }, []);
  return (
    <div>
      <h1>Lediga jobb</h1>
      <p>
        Vill du hitta en ny spännande arbetsuppgift på någon av myndigheterna
        som ingår i samarbetet? Här hittar du lediga jobb. Du hittar
        platsannonserna antingen via länk till myndighetens sida för "lediga
        jobb” eller för vissa myndigheter direkt på denna sida.
      </p>
      <p>
        <a href="https://www.ei.se/sv/arbeta-hos-oss/lediga-tjanster/">
          Energimarknadsinspektionen
        </a>
        <br />
        <a href="https://www.elsakerhetsverket.se/om-oss/arbeta-hos-oss/lediga-tjanster/">
          Elsäkerhetsverket
        </a>
        <br />
        <a href="https://forte.se/om-forte/organisation/lediga-tjanster/">
          Forskningsrådet för hälsa, arbetsliv och välfärd, Forte
        </a>
        <br />
        <a href="https://formas.se/om-formas/jobba-pa-formas/lediga-jobb.html?query=Lediga+jobb">
          Formas
        </a>
        <br />
        <a href="http://www.konkurrensverket.se/omossmeny/om-oss/jobba-hos-oss/lediga-jobb/">
          Konkurrensverket
        </a>
        <br />
        <a href="https://www.mprt.se/sv/om-oss/arbeta-hos-oss/">
          Mediemyndigheten
        </a>
        <br />
        <a href="https://www.naturvardsverket.se/om-oss/jobba-hos-oss/lediga-jobb/">
          Naturvårdsverket
        </a>
        <br />
        <a href="https://www.prv.se/sv/om-oss/jobba-hos-oss/lediga-jobb/">
          Patent- och registreringsverket
        </a>
        <br />
        <a href="https://pts.se/om-oss/jobba-hos-oss/lediga-jobb/">
          Post- och telestyrelsen
        </a>

        <br />
        <a href="https://www.rymdstyrelsen.se/om-rymdstyrelsen/lediga-tjanster/">
          Rymdstyrelsen
        </a>
        <br />
        <a href="https://www.vinnova.se/m/arbeta-hos-oss/lediga-tjanster/">
          Vinnova
        </a>
        <br />
        <a href="https://www.jobbastatligt.se/">Arbetsgivarverket</a>
      </p>
      <h2>Platsannonser</h2>
      <OpenPositionsList data={data} linkFunc={linkClick} loading={loading} />
    </div>
  );
}
export default Page;
