import { Link } from "react-router-dom";

function Page() {
  return (
    <div className="vanligsida col-sm-10">
      <h1>Pågående nätverk</h1>
      <span className="ingress">
        Här kan du läsa mer om pågående nätverk, vem du ska kontakta om du vill
        veta mer eller gå med i ett nätverk. Välkommen att delta i våra nätverk
        du som arbetar på någon av de myndigheter som deltar i Rörlighet i
        staten.
      </span>
      <br />
      <br />
      <p>
        Varmt välkommen att höra av dig om du har idéer på nya nätverk. Kontakta
        Rörlighet i statens samordnare Christina Frimodig så kan ni prata ihop
        er om upplägg, förutsättningar etc.
        <br />
        Kontakta vår samordnare Christina Frimodig,
        christina.frimodig@naturvardsverket.se eller 070-673 47 47.
      </p>

      <br />
      <br />

      <h3>Administration</h3>
      <p>
        Utbyta erfarenheter, lära av varandra och få med sig idéer att använda i
        utvecklingen av den egna verksamheten. Vi diskuterar hur man
        administrativt bäst löser olika uppgifter samt stöd till medarbetare i
        olika administrativa rutiner, ärendeprocesser och system.
      </p>
      <p>
        <strong>Målgrupp: </strong>Administratörer och handläggare
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:eva.Hejdeman@tillvaxtverket.se">
          eva.Hejdeman@tillvaxtverket.se
        </a>
      </p>
      <hr />
      <h3>Arkivarier</h3>
      <p>
        Myndigheters informationshantering är i ständig förändring tack vare
        digitaliseringen. I nätverket utbyter vi idéer och diskuterar utmaningar
        och lösningar.
      </p>
      <p>
        <strong>Målgrupp: </strong>Arkivarier
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:nils.mossberg@energimyndigheten.se">
          johanna.waage@vinnova.se
        </a>
      </p>
      <hr />
      <h3>Avtalsuppföljning och inköpsanalys</h3>
      <p>
        Just nu är nätverket inaktivt. Vill du delta i nätverket, hör av dig
        till{" "}
        <a href="mailto:annika.hagg@energimyndigheten.se">
          annika.hagg@energimyndigheten.se
        </a>
        . Vi delar erfarenheter, diskuterar utmaningar och lösningar, stöttar
        varandra och utvecklar vår verksamhet kring uppföljning och analys
        kopplat till myndigheters avtal och inköp
      </p>
      <p>
        <strong>Målgrupp:</strong> Avtalscontrollers, upphandlare och
        avtalsförvaltare som arbetar med avtalsuppföljning och/eller spendanalys
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:Info@rorlighetistaten.se">Info@rorlighetistaten.se</a>
      </p>
      <hr />
      <h3>Controller</h3>
      <p>
        Utbyta erfarenheter inom ekonomistyrningsfrågor som till exempel
        planering, budgetering och uppföljning.
      </p>
      <p>
        <strong>Målgrupp: </strong>Controllers
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:charlotte.furusten@vinnova.se">
          charlotte.furusten@vinnova.se
        </a>
      </p>
      <hr />
      <h3>GIS och geodata</h3>
      <p>
        Vi delar erfarenheter och kompetens inom geodata, GIS och kring våra
        system för att lagra, bearbeta och dela geodata internt och externt.
      </p>
      <p>
        <strong>Målgrupp: </strong>GIS-samordnare och användare av geodata och
        GIS
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:erik.axelsson@pts.se">erik.axelsson@pts.se</a>
      </p>
      <hr />
      <h3>GDPR</h3>
      <p>
        Just nu är nätverket inaktivt. Vill du starta igång nätverket igen och
        vara kontaktperson, hör gärna av dig. Vi delar best practice, diskuterar
        problem, utmaningar och lösningar med att implementera GDPR.
      </p>
      <p>
        <strong>Målgrupp: </strong>GDPR-ansvariga personer på olika myndigheter
        (dataskyddsombud/projektledare mm).
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:info@rorlighetistaten.se">info@rorlighetistaten.se</a>
      </p>
      <hr />
      <h3>HR</h3>
      <p>
        Just nu är nätverket inaktivt. Vill du starta igång nätverket igen och
        vara kontaktperson, hör gärna av dig. I HR-nätverket utbyter vi
        erfarenheter och hjälper varandra i såväl operativa som strategiska
        HR-frågor och strategisk kompetensförsörjning.
      </p>
      <p>
        <strong>Målgrupp: </strong>HR-funktioner
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:info@rorlighetistaten.se">info@rorlighetistaten.se</a>
      </p>
      <hr />
      <h3>Juridik</h3>
      <p>
        Utbyta erfarenheter kring frågor om exempelvis förvaltningslagen,
        offentlighetsprincipen, sekretess, GDPR, föreskrifter och avgifter.
      </p>
      <p>
        <strong>Målgrupp: </strong>Verksjurister
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:ulrika.Janson@sbu.se">ulrika.Janson@sbu.se</a>
      </p>
      <hr />
      <h3>Kommunikation</h3>
      <p>
        Just nu är nätverket inaktivt. Vill du starta igång nätverket igen och
        vara kontaktperson, hör gärna av dig. Utbyta erfarenheter med
        kommunikatörskollegor. Vi bestämmer tillsammans vilka frågor vi tar upp.
      </p>
      <p>
        <strong>Målgrupp: </strong>Kommunikatörer
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:info@rorlighetistaten.se">info@rorlighetistaten.se</a>
      </p>
      <hr />
      <h3>Konferenser, utbildningar och möten</h3>
      <p>
        Erfarenhetsutbyte inom konferenser, utbildningar och möten. Vi tar upp
        ämnen som till exempel konferensadministration,
        konferenshanteringssystem, digital utveckling och GDPR.
      </p>
      <p>
        <strong>Målgrupp: </strong>Alla som arbetar med att arrangera
        konferenser, utbildningar och möten
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:helena.ericsson@tillvaxtverket.se">
          helena.ericsson@tillvaxtverket.se
        </a>
      </p>
      <hr />
      <h3>Omvärldsbevakning</h3>
      <p>
        Forum för erfarenhetsutbyte och lärande inom omvärldsbevakning. Fokus på
        hur man kan arbeta med omvärldsbevakning som en del av myndighetens
        verksamhet och styrning.
      </p>
      <p>
        <strong>Målgrupp: </strong>Primärt personer som arbetar med
        omvärldsanalys som en större del av sitt arbete.
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:hans.hedin@pts.se">hans.hedin@pts.se</a>
      </p>
      <hr />
      <h3>Registratorer</h3>
      <p>
        Erfarenhetsutbyte för registratorer. Vi diskuterar arbetsuppgifter såsom
        diarieföring, arkiv, utlämnade av allmän handling och handläggarstöd.
      </p>
      <p>
        <strong>Målgrupp: </strong>Registratorer
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:Eva.FredrikssonEklund@formas.se">
          Eva.FredrikssonEklund@formas.se
        </a>
      </p>
      <hr />
      <h3>Sociala kanaler</h3>
      <p>
        Just nu är nätverket inaktivt. Vill du starta igång nätverket igen och
        vara kontaktperson, hör gärna av dig. Nätverk för personer som ansvarar
        för sociala kanaler. Innehåll: Utveckling av kanalerna, policyfrågor,
        juridiska aspekter etc.
      </p>
      <p>
        <strong>Målgrupp: </strong>Personer som jobbar med sociala kanaler
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:info@rorlighetistaten.se">info@rorlighetistaten.se</a>
      </p>
      <hr />
      <h3>Tillsyn</h3>
      <p>
        Nätverk för att utbyta erfarenheter kring arbete med tillsyn. Vad är
        tillsyn? Vilka tillsynsmetoder använder vi oss av och lämpar olika
        metoder sig bäst för olika syften/mål? Vi diskuterar olika faser av
        utredningsarbetet – t.ex. arbetssätt, utmaningar, effekter,
        beredningsgång, kommunicering, beslutsskrivning och
        uppföljning/utvärdering.
      </p>
      <p>
        <strong>Målgrupp: </strong>Handläggare och utredare som arbetar med
        tillsyn
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:jonas.grape@ei.se">jonas.grape@ei.se</a>
      </p>
      <hr />
      <h3>Upphandling</h3>
      <p>Erfarenhetsutbyte i frågor som rör upphandling och inköp.</p>
      <p>
        <strong>Målgrupp: </strong>Medarbetare som arbetar med
        upphandlingsfrågor/inköp
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:kajsa.gradin@naturvardsverket.se">
          kajsa.gradin@naturvardsverket.se
        </a>
      </p>
      <hr />
      <h3>Utgivning av publikationer</h3>
      <p>
        Vi utbyter erfarenheter och idéer kring publikationsutgivning –
        tillgänglighetsfrågor, tryckeritjänster, upphovsrätt, formgivning,
        format m.m. Vi pratar även om publikationer som kommunikationskanal, och
        hur vi kan underlätta spridning och åtkomst till den typen av
        kunskapsinnehåll.
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:asa.k.karlsson@energimyndigheten.se">
          asa.k.karlsson@energimyndigheten.se
        </a>
      </p>
      <hr />
      <h3>Utveckling av digitala verktyg</h3>
      <p>
        Vi utbyter erfarenheter, coachar och tipsar varandra om att bygga
        digitala verktyg inom staten. Det kan handla om att leverera under en
        fastställd budget, efterleva regelverk, göra det mesta av
        skattebetalarnas pengar och skapa maximal samhällsnytta viktiga. Vilka
        faktorer är viktiga för att lyckas bygga en värdefull digital produkt
        under de särskilda omständigheterna? Hur mäter vi om vi lyckats när vi
        pratar om samhällsnytta istället för intäkter?
      </p>
      <p>
        <strong>Målgrupp: </strong>Produktägare/systemägare på någon av
        myndigheterna inom Rörlighet i staten.
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:andreas.Duvhammar@tillvaxtverket.se">
          andreas.Duvhammar@tillvaxtverket.se
        </a>
      </p>
      <hr />
      <h3>Myndighetsöverskridande verksamhetsutveckling med digitalisering</h3>
      <p>
        Just nu är nätverket inaktivt. Vill du starta igång nätverket igen och
        vara kontaktperson, hör gärna av dig. Utbyta erfarenheter om
        verksamhetsutveckling som innehåller stora inslag av digitalisering och
        oftast i samarbete med fler myndigheter.
      </p>
      <p>
        <strong>Kontakt: </strong>
        <a href="mailto:info@rorlighetistaten.se">info@rorlighetistaten.se</a>
        &nbsp;
      </p>
      <div className="umb-grid">
        <div className="grid-section">
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Page;
